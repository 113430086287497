/* eslint-disable operator-linebreak */
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import TeamsControls from 'components/@home/drawers/ChannelDrawer/Form/controls/TeamsControls';
import TooltipIfFromTeam from 'components/@home/drawers/ChannelDrawer/Form/TooltipIfFromTeam';
import Checkbox from 'components/formik/Checkbox';
import PhotoUpload from 'components/formik/PhotoUpload';
import Switch from 'components/formik/Switch';
import TextField from 'components/formik/TextField';
import { FastField, Form } from 'formik';
import { arrayOf, bool, object } from 'prop-types';
import React from 'react';
import employeeShape from 'shapes/employee';
import { I18n } from 'utils/i18n';
import withNavigate from 'utils/withNavigate';
import connector from './connector';
import formik from './formik';
import ActionButtons from './formik/ActionButtons';
import EmployeesTable from './formik/EmployeesTable';
import Headline from './Headline';
import Label from './Label';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 20,
    maxWidth: 'calc(100% - 60px)',
    display: 'flex',
    flexDirection: 'column',
  },
  inputs: {
    display: 'flex',
    flex: '1 0 auto',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    paddingBottom: 30,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  type: {
    minWidth: 680,
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      minWidth: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    justifyContent: 'space-between',
  },

  upload: {
    paddingTop: 20,
    paddingRight: 20,
  },

  leave: {
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'flex-start',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  errorMessage: {
    padding: '0 20px',
    alignSelf: 'center',
  },
});

const ChannelForm = ({ classes, errors, isSuperAdmin, values, isEdit, employees, actions }) => {
  const handleReset = () => {
    actions.drawers.channel.close();
  };

  return (
    <Form className={classes.root}>
      <Headline isEdit={isEdit} />
      <div className={classes.inputs}>
        <FastField component={PhotoUpload} classes={{ root: classes.upload }} name="avatar" />

        <div>
          <Label>{I18n.t('ChannelDrawer.NameField.label')}</Label>
          <FastField
            component={TextField}
            autoComplete="off"
            placeholder={I18n.t('ChannelDrawer.NameField.placeholder')}
            name="name"
          />
          <Label>{I18n.t('ChannelDrawer.DescriptionField.label')}</Label>
          <FastField
            component={TextField}
            autoComplete="off"
            placeholder={I18n.t('ChannelDrawer.DescriptionField.placeholder')}
            name="description"
          />
          <div>
            <Label>{I18n.t('ChannelDrawer.Permissions.label')}</Label>
            <Grid rowSpacing={2} columnSpacing={0} container alignItems="top">
              <Grid item xs={1}>
                <FastField
                  component={Switch}
                  name="isReadOnly"
                  id="isReadOnly"
                  multiple={false}
                  sx={{ margin: 0 }}
                />
              </Grid>
              <Grid item xs={11} component="label" htmlFor="isReadOnly" sx={{ pt: 0 }}>
                <Typography>{I18n.t('ChannelDrawer.Permissions.Read only')}</Typography>
                <Typography variant="caption">
                  {I18n.t('ChannelDrawer.Permissions.Read only description')}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <FastField
                  component={Switch}
                  name="isMuteable"
                  id="isMuteable"
                  multiple={false}
                  sx={{ margin: 0 }}
                />
              </Grid>
              <Grid item xs={11} component="label" htmlFor="isMuteable" sx={{ pt: 0 }}>
                <Typography>{I18n.t('ChannelDrawer.Permissions.Silence notifications')}</Typography>
                <Typography variant="caption">
                  {I18n.t('ChannelDrawer.Permissions.Silence notifications description')}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>

      <TeamsControls actions={actions} />

      <FastField component={EmployeesTable} employees={employees} name="members" isEdit={isEdit} />

      <div className={classes.actions}>
        {(isSuperAdmin || !values.isReadOnly) && (
          <div className={classes.leave}>
            <TooltipIfFromTeam member={values.me} teams={[]}>
              <FastField
                disabled={values.optOutDisabled}
                component={Checkbox}
                label={I18n.t('ChannelDrawer.Opt out from this group')}
                name="isOptedOut"
              />
            </TooltipIfFromTeam>
          </div>
        )}
        <div className={classes.errorContainer}>
          {errors.server && (
            <Typography align="right" color="error" className={classes.errorMessage}>
              {errors.server}
            </Typography>
          )}
        </div>
        <ActionButtons onReset={handleReset} isEdit={isEdit} />
      </div>
    </Form>
  );
};

ChannelForm.propTypes = {
  classes: object.isRequired,
  actions: object.isRequired,
  isEdit: bool.isRequired,
  isSuperAdmin: bool.isRequired,
  values: object.isRequired,
  employees: arrayOf(employeeShape).isRequired,
  errors: object.isRequired,
};

export default withStyles(styles)(withNavigate(connector(formik(ChannelForm))));

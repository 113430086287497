import { useDispatch, useSelector } from 'react-redux';
import Channel from 'services/api/Channel';
import Socket from 'services/Socket';
import { invalidateGroupsTags } from 'store/app/entities/GroupsSlice';
import { OPEN_CHANNEL } from 'store/app/ui/ChannelsPanel/action';
import authCompany from 'store/selectors/authCompany';
import memberChannelsStatus from 'store/selectors/memberChannelsStatus';

export const DESTROY_CHANNEL = 'DESTROY_CHANNEL';
export const DESTROY_CHANNEL_PENDING = 'DESTROY_CHANNEL_PENDING';
export const DESTROY_CHANNEL_FULFILLED = 'DESTROY_CHANNEL_FULFILLED';

export const FIND_CHANNEL = 'FIND_CHANNEL';
export const FIND_CHANNEL_PENDING = 'FIND_CHANNEL_PENDING';
export const FIND_CHANNEL_FULFILLED = 'FIND_CHANNEL_FULFILLED';

export const GET_MEMBER_CHANNELS = 'GET_MEMBER_CHANNELS';
export const GET_MEMBER_CHANNELS_PENDING = 'GET_MEMBER_CHANNELS_PENDING';
export const GET_MEMBER_CHANNELS_FULFILLED = 'GET_MEMBER_CHANNELS_FULFILLED';
export const GET_MEMBER_CHANNELS_REJECTED = 'GET_MEMBER_CHANNELS_REJECTED';

export const JOIN_CHANNEL = 'JOIN_CHANNEL';
export const JOIN_CHANNEL_FULFILLED = 'JOIN_CHANNEL_FULFILLED';

export const REMOVE_CHANNEL = 'REMOVE_CHANNEL';

export const SET_CHANNEL = 'SET_CHANNEL';

export const WATCH_CHANNEL = 'WATCH_CHANNEL';

export const SET_ARCHIVED_CHANNEL = 'SET_ARCHIVED_CHANNEL';
export const SET_ARCHIVED_CHANNEL_PENDING = 'SET_ARCHIVED_CHANNEL_PENDING';
export const SET_ARCHIVED_CHANNEL_FULFILLED = 'SET_ARCHIVED_CHANNEL_FULFILLED';

export const SET_MUTED_CHANNEL = 'SET_MUTED_CHANNEL';
export const SET_MUTED_CHANNEL_PENDING = 'SET_MUTED_CHANNEL_PENDING';
export const SET_MUTED_CHANNEL_FULFILLED = 'SET_MUTED_CHANNEL_FULFILLED';

export const SET_PINNED_CHANNEL = 'SET_PINNED_CHANNEL';
export const SET_PINNED_CHANNEL_PENDING = 'SET_PINNED_CHANNEL_PENDING';
export const SET_PINNED_CHANNEL_FULFILLED = 'SET_PINNED_CHANNEL_FULFILLED';

const destroy = channelId => async dispatch => {
  await dispatch({
    type: DESTROY_CHANNEL,
    payload: Channel.destroy(channelId),
    meta: { channel_id: channelId },
  });
  dispatch(invalidateGroupsTags([{ type: 'Groups', id: 'PARTIAL-LIST' }]));
};

const find = channelId => ({
  type: FIND_CHANNEL,
  payload: Channel.find(channelId),
  meta: { channelId },
});

const emitChannel = (_id, dispatch, getState) => {
  const { elements } = getState();

  const previousId = elements.ChannelsPanel.currentId;

  const params = (channel, status) => ({ channel, status });

  if (previousId) Socket.emit('channelStatus', params(previousId, false));
  Socket.emit('channelStatus', params(_id, true));
};

const join = channelId => async dispatch => {
  await dispatch({
    type: JOIN_CHANNEL,
    payload: Channel.join(channelId),
    meta: { channel_id: channelId },
  });
  dispatch(invalidateGroupsTags([{ type: 'Groups', id: 'PARTIAL-LIST' }]));
};

const open = (channelId, messageId) => (dispatch, getState) => {
  emitChannel(channelId, dispatch, getState);

  dispatch({
    type: OPEN_CHANNEL,
    payload: channelId,
    meta: { messageId },
  });

  localStorage.setItem('currentId', channelId);
};

const remove = channelId => async dispatch => {
  dispatch({
    type: REMOVE_CHANNEL,
    payload: channelId,
  });
};

const set = rawChannel => dispatch => {
  dispatch({
    type: SET_CHANNEL,
    payload: rawChannel,
  });
};

const watch = channelId => ({
  type: WATCH_CHANNEL,
  payload: channelId,
});

const setArchived = (companyId, employeeId, channelId, archived) => async dispatch => {
  dispatch({
    type: SET_ARCHIVED_CHANNEL,
    payload: Channel.setArchived(channelId, archived),
    meta: { companyId, employeeId, channelId, archived },
  });
};

const setPinned = (companyId, employeeId, channelId, pinned) => async dispatch => {
  dispatch({
    type: SET_PINNED_CHANNEL,
    payload: Channel.setPinned(channelId, pinned),
    meta: { companyId, employeeId, channelId, pinned },
  });
};

const setMuted = (companyId, employeeId, channelId, muted) => async dispatch => {
  dispatch({
    type: SET_MUTED_CHANNEL,
    payload: Channel.setMuted(channelId, muted),
    meta: { companyId, employeeId, channelId, muted },
  });
};

const getMemberChannels = () => {
  return {
    type: GET_MEMBER_CHANNELS,
    payload: Channel.getMemberChannels(),
  };
};

export const useGetMemberChannels = () => {
  const dispatch = useDispatch();
  const company = useSelector(authCompany);
  const ret = useSelector(memberChannelsStatus);
  if (!ret.isFetching && !ret.isError && (!company || ret.rehydrated || ret.isUninitialized)) {
    dispatch(getMemberChannels());
  }
  return ret;
};

export default {
  destroy,
  find,
  getMemberChannels,
  join,
  open,
  remove,
  set,
  setArchived,
  setMuted,
  setPinned,
  watch,
};

import Team from 'services/api/Team';
import { invalidateTeamsTags } from 'store/app/entities/TeamsSlice';

export const FIND_TEAM = 'FIND_TEAM';
export const FIND_TEAM_PENDING = 'FIND_TEAM_PENDING';
export const FIND_TEAM_FULFILLED = 'FIND_TEAM_FULFILLED';
export const FIND_TEAM_REJECTED = 'FIND_TEAM_REJECTED';

export const LOAD_TEAMS = 'LOAD_TEAM';
export const LOAD_TEAMS_PENDING = 'LOAD_TEAM_PENDING';
export const LOAD_TEAMS_FULFILLED = 'LOAD_TEAM_FULFILLED';
export const LOAD_TEAMS_REJECTED = 'LOAD_TEAM_REJECTED';

export const CREATE_TEAM = 'CREATE_TEAM';
export const CREATE_TEAM_PENDING = 'CREATE_TEAM_PENDING';
export const CREATE_TEAM_FULFILLED = 'CREATE_TEAM_FULFILLED';

export const UPDATE_TEAM = 'UPDATE_TEAM';
export const UPDATE_TEAM_PENDING = 'UPDATE_TEAM_PENDING';
export const UPDATE_TEAM_FULFILLED = 'UPDATE_TEAM_FULFILLED';

export const DESTROY_TEAM = 'DESTROY_TEAM';

export const MAGIC = 'MAGIC';
export const DESTROY_TEAM_PENDING = 'DESTROY_TEAM_PENDING';
export const DESTROY_TEAM_FULFILLED = 'DESTROY_TEAM_FULFILLED';

const load = () => ({
  type: LOAD_TEAMS,
  payload: Team.list(),
});

const create = form => ({
  type: CREATE_TEAM,
  payload: Team.create(form),
});

const update = (id, form) => ({
  type: UPDATE_TEAM,
  payload: Team.update(id, form),
});

const find = id => ({
  type: FIND_TEAM,
  payload: Team.find(id),
});

const destroy = id => ({
  type: DESTROY_TEAM,
  payload: Team.destroy(id),
  meta: { teamId: id },
});

const addBonpreuSubteams = () => ({
  type: MAGIC,
  payload: Team.addBonpreuSubteams(),
});

const createWithInvalidation = form => dispatch => {
  return dispatch(create(form)).then(result => {
    dispatch(invalidateTeamsTags(['Teams']));
    return result;
  });
};

const updateWithInvalidation = (id, form) => dispatch => {
  return dispatch(update(id, form)).then(result => {
    dispatch(invalidateTeamsTags(['Teams']));
    return result;
  });
};

const destroyWithInvalidation = id => dispatch => {
  return dispatch(destroy(id)).then(result => {
    dispatch(invalidateTeamsTags(['Teams']));
    return result;
  });
};

export default {
  load,
  find,
  addBonpreuSubteams,
  create: createWithInvalidation,
  update: updateWithInvalidation,
  destroy: destroyWithInvalidation,
};

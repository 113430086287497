/* eslint-disable no-console */
import { PRE_SELECTED_COUNTRIES_PER_LANGUAGE } from 'constants/languages';
import { withFormik } from 'formik';
import { I18n } from 'utils/i18n';
import * as Yup from 'yup';
import prefixFormat from './prefixFormat';

const formik = withFormik({
  validationSchema: Yup.object().shape({
    prefix: Yup.string().required('Please choose your country prefix'),
    phone: Yup.number().required('Please enter your number'),
  }),

  validate: values => {
    const errors = {};
    const { length } = prefixFormat(values.prefix).match(/#/g) || [];
    if (values.phone && values.phone.replace(/\D/g, '').length !== length) {
      errors.phone = I18n.t('AuthErrors.PhoneFormatError');
    }

    if (values.prefix === 'Choose country prefix') {
      errors.prefix = 'Please choose your country prefix';
    }

    return errors;
  },
  mapPropsToValues: ({ keepSessionOpen, login: { form }, locale }) => ({
    prefix: form.prefix || PRE_SELECTED_COUNTRIES_PER_LANGUAGE[locale],
    phone: form.phone,
    keepSessionOpen: keepSessionOpen ? ['on'] : [],
  }),
  handleSubmit: async (
    { keepSessionOpen, prefix, phone, returnUrl },
    { props, setSubmitting, setErrors },
  ) => {
    try {
      const inviteToken =
        props.returnUrl && props.returnUrl.match(/\/accept-invite\/([a-z0-9]+)$/)?.[1];
      props.actions.authConfig.setKeepSessionOpen(keepSessionOpen[0] === 'on');
      await props.actions.login.form.update({ prefix, phone });
      await props.actions.login.sendSMS(`${prefix}-${phone}`, {
        keepSessionOpen: keepSessionOpen[0] === 'on',
        inviteToken,
        locale: props.locale,
      });
      setSubmitting(false);
      props.navigate('confirmation');
    } catch (e) {
      if (e.data?.data?.status === 404) {
        if (returnUrl) {
          console.log(returnUrl);
        }
        props.navigate('../login-no-invitation');
        return;
      }
      setSubmitting(false);
      const errCode = e.data?.key || 'UnexpectedError';
      setErrors({ phone: I18n.t(`AuthErrors.${errCode}`) });
    }
  },
  displayName: 'LoginForm',
});

export default formik;

import { arrayOf, bool, number, shape, string } from 'prop-types';
import messageShape from 'shapes/message';

export default shape({
  _id: string.isRequired,
  name: string.isRequired,
  isDirect: bool.isRequired,
  isMember: bool.isRequired,
  isMuteable: bool,
  isReadOnly: bool,
  avatar: string.isRequired,
  complete: bool.isRequired,
  employees_ids: arrayOf(string).isRequired,
  lastMessage_id: string,
  messagesUnreadCount: number.isRequired,
  messagesTotalCount: number.isRequired,
  mandatoriesUnreadCount: number,
  page: number,
  pinnedMessage: messageShape,
});

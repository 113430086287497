/** @flow */
import Rule from '@mui/icons-material/Rule';
import UnfoldLess from '@mui/icons-material/UnfoldLess';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import { Alert, Avatar, Button, IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
  TreeItem2Content,
  TreeItem2GroupTransition,
  TreeItem2Icon,
  TreeItem2IconContainer,
  TreeItem2Label,
  TreeItem2Provider,
  TreeItem2Root,
} from '@mui/x-tree-view';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { useTreeItem2 } from '@mui/x-tree-view/useTreeItem2/useTreeItem2';
import SectionBody from 'components/@home/@settings/Tabs/SectionBody';
import SectionTitle from 'components/@home/@settings/Tabs/SectionTitle';
import DeleteDialog from 'components/common/DeleteDialog';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EditIcon from 'mdi-react/EditIcon';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import teamsActions from 'store/app/entities/teams/action';
import { useListTeamsQuery } from 'store/app/entities/TeamsSlice';
import rulesDrawer from 'store/app/ui/drawers/rules/action';
import teamDrawer from 'store/app/ui/drawers/team/action';
import { I18n } from 'utils/i18n';
import listFormat from 'utils/listFormat';
import { useFeatureToggle } from '@flopflip/react-redux';
import isCompanyAdminSelector from 'store/selectors/isCompanyAdmin';

import listToTree from 'utils/listToTree';

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
}));

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    flexDirection: 'column',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  iconContainer: {
    color: theme.palette.text.secondary,
  },
}));

type CustomTreeItemProps = {|
  id: string,
  itemId: string,
  label: string,
  disabled: boolean,
  children: Node,
  setTeamToDelete: Function,
  initials: string,
|};

const getAllChildNames = team => {
  if (!team?.children) {
    return [];
  }
  return team.children.reduce((names, childTeam) => {
    return [...names, childTeam.name, ...getAllChildNames(childTeam)];
  }, []);
};

const CustomTreeItem = React.forwardRef((props: CustomTreeItemProps, ref) => {
  const integratedUsers = useFeatureToggle('integrated-users');
  const isCompanyAdmin = useSelector(isCompanyAdminSelector);

  const { id, itemId, label, disabled, children, setTeamToDelete, ...other } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
    publicAPI,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  const team = publicAPI.getItem(itemId);
  const editTeam = useCallback(() => {
    dispatch(teamDrawer.open(team));
  }, [dispatch, team]);

  const rules = useCallback(() => {
    dispatch(rulesDrawer.open(team));
  }, [dispatch, team]);

  const deleteTeam = useCallback(() => {
    setTeamToDelete(team);
  }, [setTeamToDelete, team]);

  return (
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root {...getRootProps(other)}>
        <CustomTreeItemContent {...getContentProps()}>
          <TreeItem2IconContainer className={classes.iconContainer} {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          <Box sx={{ flexGrow: 1, display: 'flex', gap: 1, alignItems: 'center' }}>
            <Avatar className={classes.avatar}>{other.initials}</Avatar>
            <TreeItem2Label {...getLabelProps()}>
              <Typography component="div">{label}</Typography>
              <Typography variant="caption">{team.description}</Typography>
            </TreeItem2Label>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {!integratedUsers || isCompanyAdmin ? (
                <IconButton onClick={rules} size="large">
                  <Rule />
                </IconButton>
              ) : null}
              <IconButton onClick={editTeam} size="large">
                <EditIcon />
              </IconButton>
              <IconButton onClick={deleteTeam} size="large">
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        </CustomTreeItemContent>
        {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});

const getAllItemsWithChildrenItemIds = items => {
  const itemIds = [];
  const registerItemId = item => {
    if (item.children?.length) {
      itemIds.push(item.id);
      item.children.forEach(registerItemId);
    }
  };

  items.forEach(registerItemId);

  return itemIds;
};

const TeamsSettings = () => {
  const locale = useSelector(state => state.i18n.locale);
  const [expandedItems, setExpandedItems] = useState([]);
  const classes = useStyles();
  const [teamToDelete, setTeamToDelete] = useState(null);
  const dispatch = useDispatch();

  const { data } = useListTeamsQuery({ page: null, perPage: 0, sort: null, sortBy: null });
  const teams = useMemo(() => listToTree(data?.teams), [data?.teams]);

  const confirmDelete = useCallback(() => {
    if (teamToDelete) {
      dispatch(teamsActions.destroy(teamToDelete._id));
    }
    setTeamToDelete(null);
  }, [dispatch, teamToDelete]);

  const closeDialog = useCallback(() => {
    setTeamToDelete(null);
  }, []);

  const addTeam = useCallback(() => {
    dispatch(teamDrawer.open());
  }, [dispatch]);

  const handleExpandedItemsChange = useCallback((_, newExpanded) => {
    setExpandedItems(newExpanded);
  }, []);

  const handleExpandClick = useCallback(() => {
    setExpandedItems(expandedItems.length === 0 ? getAllItemsWithChildrenItemIds(teams) : []);
  }, [expandedItems.length, teams]);

  const allChildNames = getAllChildNames(teamToDelete);
  return (
    <div className={classes.root}>
      <SectionTitle title={I18n.t('SettingsMenu.teams')}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleExpandClick}
            startIcon={expandedItems.length === 0 ? <UnfoldMore /> : <UnfoldLess />}
          >
            {I18n.t(`TeamsSettings.${expandedItems.length === 0 ? 'Expand All' : 'Collapse All'}`)}
          </Button>
          <Button onClick={addTeam} variant="contained" color="primary">
            {I18n.t('TeamsSettings.New')}
          </Button>
        </Box>
      </SectionTitle>
      <SectionBody>
        <RichTreeView
          items={teams}
          slots={{ item: CustomTreeItem }}
          slotProps={{ item: { setTeamToDelete } }}
          expandedItems={expandedItems}
          onExpandedItemsChange={handleExpandedItemsChange}
        />
      </SectionBody>
      <DeleteDialog
        typeBack={teamToDelete?.name}
        message={I18n.t('TeamsSettings.ConfirmDelete', { name: teamToDelete?.name })}
        isOpen={teamToDelete}
        onConfirm={confirmDelete}
        onClose={closeDialog}
      >
        {teamToDelete?.children?.length ? (
          <Alert severity="warning">
            {I18n.t('TeamsSettings.WillDeleteChildren', {
              teams:
                allChildNames.length > 3
                  ? I18n.t('TeamsSettings.AndMore', {
                      names: allChildNames.slice(0, 3).join(', '),
                      count: allChildNames.length - 3,
                    })
                  : listFormat(locale, allChildNames),
            })}
          </Alert>
        ) : null}
      </DeleteDialog>
    </div>
  );
};

export default TeamsSettings;

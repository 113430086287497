import { Box, Typography } from '@mui/material';
import DrawerProvider from 'components/common/Drawer/DrawerProvider';
import Loading from 'components/common/Loading';
import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ommnioBotDrawer from 'store/app/ui/drawers/ommnioBotApp/action';
import { I18n } from 'utils/i18n';
import useActions from 'utils/useActions';

const OmmnioBotDrawer = () => {
  const isFetching = false;

  const visible = useSelector(state => state.drawers.ommnioBot.isOpen);

  const methods = useForm({});

  const hide = useActions(ommnioBotDrawer.close);

  const handleSubmit = useCallback(
    data => {
      console.log(data);
      hide();
    },
    [hide],
  );

  return (
    <DrawerProvider
      name="OmmnioBotDrawer"
      visible={visible}
      onClose={hide}
      title={I18n.t('OmmnioBot.title')}
      onSubmit={methods.handleSubmit(handleSubmit)}
      sx={{ width: 1000, maxWidth: '90vw' }}
      drawerContent={
        <FormProvider {...methods}>
          {isFetching ? (
            <Loading />
          ) : (
            <Box>
              <Typography>{I18n.t('OmmnioBot.title')}</Typography>
            </Box>
          )}
        </FormProvider>
      }
    />
  );
};

export default OmmnioBotDrawer;

import { combineReducers } from 'redux';
import acknowledgeCreate from './acknowledgeCreate/reducer';
import analytics from './analytics/reducer';
import app from './app/reducer';
import channel from './channel/reducer';
import direct from './direct/reducer';
import employee from './employee/reducer';
import employeeImports from './employeeImports/reducer';
import employeeList from './employeeList/reducer';
import fillForm from './fillForm/reducer';
import form from './form/reducer';
import formAnalytics from './formAnalytics/reducer';
import hrBot from './hrBot/reducer';
import launcherApp from './launcherApp/reducer';
import media from './media/reducer';
import ommnioBot from './ommnioBotApp/reducer';
import profile from './profile/reducer';
import remote from './remote/reducer';
import rules from './rules/reducer';
import settings from './settings/reducer';
import team from './team/reducer';
import timeTracking from './timeTracking/reducer';
import whistleblowerBot from './whistleblowerBot/reducer';

export default combineReducers({
  acknowledgeCreate,
  analytics,
  app,
  channel,
  direct,
  employee,
  employeeImports,
  employeeList,
  fillForm,
  formAnalytics,
  form,
  hrBot,
  launcherApp,
  media,
  ommnioBot,
  profile,
  remote,
  rules,
  settings,
  team,
  timeTracking,
  whistleblowerBot,
});

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import authUser from 'store/selectors/authUser';
import populateEmployees from 'store/selectors/populateEmployees';
import channels from 'store/app/entities/channels/action';
import currentChannel from 'store/selectors/currentChannel';

const mapStateToProps = state => {
  const channel = currentChannel(state);
  const lastSeen = channel?.lastSeen;
  const isDirect = channel ? channel.isDirect || channel.fake : true;
  const users = Object.values(populateEmployees(state)) || [];
  const employee = isDirect ? users.find(u => u.user?._id === channel._id) : null;
  const { isOnline, isTyping } = employee?.user || {};
  const myId = authUser(state)?._id;
  const typingEmployee = isDirect
    ? isTyping && isTyping[myId] && employee
    : users.find(u => u.user?.isTyping && u.user.isTyping[channel._id] && u.user._id !== myId);
  const typing = typingEmployee?.firstName;
  return {
    isDirect,
    userCount: channel?.memberCount || channel?.employees?.length || 0,
    lastSeen,
    isOnline,
    typing,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    channels: bindActionCreators(channels, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);

/* eslint-disable prefer-destructuring */
export const BACKEND_URL = process.env.BACKEND_URL;
export const SOCKETS_URL = process.env.SOCKETS_URL;
export const APP_DOMAIN = process.env.APP_DOMAIN;
export const IS_DEV = process.env.NODE_ENV === 'development';
export const FIREBASE_ANALYTICS_MEASUREMENT = process.env.FIREBASE_ANALYTICS_MEASUREMENT;
export const HR_BOT_APP_ID = '5fe915983beba1318a4cfbc5';
export const WHISTLEBLOWER_BOT_APP_ID = 'VCI6HH1QJEXD';
export const TIME_TRACKING_APP_ID = '5fe8c4103beba1318a4cfb66';
export const TIME_TRACKING_USER_ID = '5dc04ff620e252001061eb8c';
export const STRIPE_KEY = process.env.STRIPE_KEY;
export const LAUNCHER_APP_ID = 'ALVU1HHO3COZ';
export const OMMNIO_BOT_APP_ID = '4LBA7AYLAUCI';

import { Switch } from '@mui/material';
import { bool, node, object, string } from 'prop-types';
import React from 'react';

const FormikSwitch = ({ field, form, color, label, disabled, className, size, ...rest }) => {
  const { name, onChange, onBlur } = field;
  const { values } = form;
  const checked = values[name][0] === 'on';
  return (
    <Switch
      name={name}
      onBlur={onBlur}
      checked={checked}
      value="on"
      onChange={onChange}
      color={color}
      label={label}
      disabled={disabled}
      className={className}
      size={size}
      {...rest}
    />
  );
};

FormikSwitch.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
  color: string.isRequired,
  label: node.isRequired,
  disabled: bool,
  className: string,
  size: string,
};

FormikSwitch.defaultProps = {
  disabled: false,
  className: null,
  size: undefined,
};

export default FormikSwitch;

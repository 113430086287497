import { Typography } from '@mui/material';
import { node, object } from 'prop-types';
import React, { memo } from 'react';

const styles = {
  root: {
    marginTop: '1.333em',
    marginBottom: '0.467em',
    fontSize: 15,
    fontWeight: 600,
  },
};

const Label = ({ children, sx }) => {
  return (
    <Typography gutterBottom={7} variant="body1" sx={{ ...styles.root, ...sx }}>
      {children}
    </Typography>
  );
};

Label.propTypes = {
  children: node.isRequired,
  sx: object,
};

Label.defaultProps = {
  sx: {},
};

export default memo(Label);

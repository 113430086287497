import { OPEN_OMMNIO_BOT_APP_DRAWER, CLOSE_OMMNIO_BOT_APP_DRAWER } from './action';

const initialState = {
  isOpen: false,
};

const OmmnioBotAppDrawer = (state = initialState, { type }) => {
  switch (type) {
    case OPEN_OMMNIO_BOT_APP_DRAWER:
      return {
        ...state,
        isOpen: true,
      };

    case CLOSE_OMMNIO_BOT_APP_DRAWER:
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
};

export default OmmnioBotAppDrawer;

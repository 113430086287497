import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQuery from 'store/app/entities/baseQuery';

const initialState = {
  groups: {},
};

const groupEndpoints = build => ({
  list: build.query({
    query: ({
      search,
      searchBy = search ? 'name description' : undefined,
      page = 1,
      perPage = 25,
      sortBy = 'name',
      sort = 'asc',
      admin,
      allVisible,
    } = {}) => {
      const params = new URLSearchParams(
        Object.entries({
          search,
          searchBy,
          page,
          perPage,
          sortBy,
          sort,
          admin,
          allVisible,
        }).filter(([, value]) => value !== undefined && value !== null && value !== ''),
      );
      return {
        url: `v1/group?${params.toString()}`,
      };
    },
    transformResponse: response => response.data,
    providesTags: result =>
      result?.groups
        ? [
            ...result.groups.map(({ _id: id }) => ({ type: 'Groups', id })),
            { type: 'Groups', id: 'PARTIAL-LIST' },
          ]
        : [{ type: 'Groups', id: 'PARTIAL-LIST' }],
  }),
});

const Groups = createApi({
  reducerPath: 'groups',
  baseQuery,
  tagTypes: ['Groups'],
  initialState,
  keepUnusedDataFor: 30,
  endpoints: groupEndpoints,
});

export const {
  useListQuery,
  useLazyListQuery,
  util: { invalidateTags: invalidateGroupsTags },
} = Groups;

export const { middleware: groupsMiddleware, reducer: groupsReducer } = Groups;

export const OPEN_OMMNIO_BOT_APP_DRAWER = 'OPEN_OMMNIO_BOT_APP_DRAWER';
export const CLOSE_OMMNIO_BOT_APP_DRAWER = 'CLOSE_OMMNIO_BOT_APP_DRAWER';

const open = data => ({
  type: OPEN_OMMNIO_BOT_APP_DRAWER,
  payload: data,
});

const close = () => ({
  type: CLOSE_OMMNIO_BOT_APP_DRAWER,
});

export default { open, close };
